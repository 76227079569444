export const userColumns =[
    { field: 'id', headerName: 'ID', width: 70 },{
    field:"user", headerName:"Photo", width: 80, renderCell: (params) => {
        return (
            <div className="cellWithImg">
                <img className="cellImg" src={params.row.image} alt="avatar"/>
                {params.row.driversname}
            </div>
        );
    },
},
{
    field: "driverfirstName", headerName:"First Name", width: 100,
},
{
    field: "driverlastName", headerName:"Last Name", width: 100,
},
{
    field: "nationalID",
    headerName: "National ID",
    width: 100,
},
{
    field: "driverPhone",
    headerName: "Phone Number",
    width: 100,
},
{
    field: "groupname",
    headerName: "Sacco",
    width: 100,
},
{
    field: "county",
    headerName: "County",
    width: 100,
    // renderCell: (params) => {
    //     return (
    //         <div className={`cellWithStatus ${params.row.status}`}>{params.row.status}</div>
    //     )
    // }
},
{
    field: "tuktukPlateNo",
    headerName: "TukTuk Plate Number",
    width: 130,
}

];
// temporary data

// export const userRows = [
//     {
//         id: 1,
//         username: "Snow",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "passive",
//         age: 35,
//     },
//     {
//         id: 2,
//         username: "Jon",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "active",
//         age: 35,
//     },
//     {
//         id: 3,
//         username: "Mark",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "active",
//         age: 35,
//     },
//     {
//         id: 4,
//         username: "Will",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "passive",
//         age: 35,
//     },
//     {
//         id: 5,
//         username: "Jane",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "active",
//         age: 35,
//     },
//     {
//         id: 6,
//         username: "Jane",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "active",
//         age: 35,
//     },
//     {
//         id: 7,
//         username: "Jane",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "active",
//         age: 35,
//     },
//     {
//         id: 8,
//         username: "Jane",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "active",
//         age: 35,
//     },
//     {
//         id: 9,
//         username: "Jane",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "active",
//         age: 35,
//     },
//     {
//         id: 10,
//         username: "Jane",
//         img: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-614810.jpg&fm=jpg",
//         email: "snowis@gmail.com",
//         status: "active",
//         age: 35,
//     }
    
// ];