import "./single.scss";
import NavBar from '../../components/navbar/Navbar';
import AppBar from '../../components/AppBar/AppBar';
import { React, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import {db} from '../../firebase';
import {Image, Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import tukonlogo from "../../assets/tuktukoplogo.png"
import QRCode  from "qrcode";
import font from '../../assets/fonts/Nunito-Regular.ttf';
import ntsalogo from '../../assets/header.png';

const Single = () => {
  const path = window.location.pathname.split("/")[2];
  // console.log(path);

  const [data,setData] = useState({});

  const [qrcode, setQrcode] = useState("");
  const [driverImage, setDriverImage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      // let list = [];
      try {
        const docRef = doc(db, "drivers", path);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data());
          setData(docSnap.data());
          setDriverImage(docSnap.data().image);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        // console.log(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  },[path]);


  // pdf stylesheet
  Font.register({ family: 'Nunito', src: font, format: "truType" });

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Nunito',
      flexDirection: 'column',
      backgroundColor: '#FFFF',
      padding: 20,
    },
    row: {
      flexDirection: 'row',
      // justifyContent: 'space-between'
      // padding: 10,
    },
    row2: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    section: {
      // margin: 10,
      paddingTop: 4,
      paddingLeft: 4,
      paddingRight: 17,
      flexDirection: 'column',
      // flexGrow: 1,
      fontSize: 12,
    },
    image: {
      width: '50%',
      // padding: 10,
    },
    heading: {
      fontSize: 17,
      fontWeight: 700,
      color: "#82ad37"
    },
    text: {
      fontSize: 12,
    },
    smalltext: {
      fontSize: 11,
      textAlign: 'center',
      paddingLeft: 20
    },
    spantext: {
      fontSize: 8,
      textAlign: 'center',
      paddingLeft: 20
    },
    hr: {
      width: 200,
      height: 2,
      color: 'black'
    }
  });
  // const driverimage = data.image;
  // console.log(data.image);

  // Qr code component

  QRCode.toDataURL(`https://tuktuktransport.org/${data.driverfirstName}${data.driverlastName}`)
      .then(url => {
        console.log(url);
        setQrcode(url);
      })
      .catch(err => {
        console.error(err);
  });

  const MyDocument = () => (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.row}>
          <View style={styles.section}>
            <Text style={styles.heading}>TUK TUK OPERATORS NETWORK</Text>
            <Text style={styles.text}>Enhancing first and last mile services</Text>
          </View>
          <View style={styles.section}>
            <Image 
              src={tukonlogo}
              style={{ width: 65, height: 50}}
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.section}>
            <Image 
            src={driverImage}
            style={{ width: 100, height: 100, border: 1 }}
            />
          </View>
          <View style={styles.section}>
            <Text>{data.driverfirstName} {data.driverlastName}</Text>
            <Text>National ID: {data.nationalID}</Text>
            <Text>County: {data.county}</Text>
            <Text>Sub County: {data.subCounty}</Text>
            <Text>Sacco: {data.groupname}</Text>
          </View>
        </View>
        <View style={{height: 60}}></View>
        {/* Backside of ID */}
        <View style={styles.row2}>
          <View style={styles.section}>
            <Image 
              src={qrcode}
              style={{ width: 100, height: 100, border: 1 }}
            />
          </View>
          <View style={styles.section}>
            <Image 
              src={ntsalogo}
              style={{ width: 90, height: 50}}
            />
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.smalltext}>This card is the property of Tuk Tuk Operators Network.</Text>
          <Text style={styles.spantext}>P.O BOX 10462-00200 Nairobi, Kenya Tel: +254798682601</Text>
          <Text style={styles.spantext}>Email: info@tuktuktransport.org Website: www.tuktuktransport.org</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="flex">
      <AppBar/>
      <NavBar/>
      <div className="singleContainer w-full list mt-20 ml-8">
        <div className="lg:ml-60 mt-10">
        <div className="top flex-col md:flex-row">
          <div className="left">
            {/* <div className="editButton">Edit</div> */}
            <h1 className="itemTitle title">Driver's Details</h1>
            <div className="item flex-col md:flex-row">
              <div>
                <img src={data.image} alt="" className="itemImg" />
              </div>
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">First Name:</span>
                  <span className="itemValue">{data.driverfirstName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Last Name:</span>
                  <span className="itemValue">{data.driverlastName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">National ID:</span>
                  <span className="itemValue">{data.nationalID}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data.driverPhone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Gender:</span>
                  <span className="itemValue">{data.driverGender}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Year of Birth:</span>
                  <span className="itemValue">{data.driverYearOfBirth}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Driver Type:</span>
                  <span className="itemValue">{data.driverType}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Driver's License:</span>
                  <span className="itemValue">{data.driverLicense}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Tuk Tuk Number Plate:</span>
                  <span className="itemValue">{data.tuktukPlateNo}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right left-5 md:left-0 top-0 md:top-14">
            <div className="item">
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">County:</span>
                  <span className="itemValue">{data.county}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Sub County:</span>
                  <span className="itemValue">{data.subCounty}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Ward:</span>
                  <span className="itemValue">{data.ward}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Stage:</span>
                  <span className="itemValue">{data.stage}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Group:</span>
                  <span className="itemValue">{data.groupname}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Tuk Tuk Owner:</span>
                  <span className="itemValue">{data.tuktukOwner}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Tuk Tuk Owner Phone Number:</span>
                  <span className="itemValue">{data.tuktukOwnerPhoneNumber}</span>
                </div>
                <div className="mt-5">
                    <PDFDownloadLink document={<MyDocument />} fileName={`${data.driverfirstName}${data.driverlastName}.pdf`} className="bg-black text-white py-1 px-3 rounded-full">
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download Now'
                      }
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right">
            <Chart aspect={3 / 1} title="User Spending (Last 6 Months)"/>
          </div> */}
        </div>
        {/* <div className="bottom">
        <h1 className="title">Last Transactions</h1>
          <List/>
        </div> */}
      </div>
    </div>
    </div>
  )
}

export default Single