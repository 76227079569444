import React, { useContext } from 'react';
import {Link, useNavigate} from 'react-router-dom';
// import '../home.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import StoreIcon from '@mui/icons-material/Store';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { AuthContext } from '../../../context/AuthContext';
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase.js";
import { useState } from 'react';

const NavBar = () => {

  const {dispatch} = useContext(AuthContext);

  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const handleLogout = (e) => {
    e.preventDefault();

    signOut(auth).then(() => {
    // Sign-out successful.
        navigate("/login")
    }).catch((error) => {
    // An error happened.
    setError(true)
    console.log(error);
    });

  }

  const links = [
    {
      id: 1,
      child: ( <> <DashboardIcon/> Dashboard </> ),
      navto: '/countyadmindash'
    },
    {
      id: 2,
      link: 'Drivers',
      child: ( <> <ElectricRickshawIcon/> Drivers </> ),
      navto: '/countyadmindash/drivers-county'
    },
    {
        id: 3,
        link: 'Statistics',
        child: ( <> <InsertChartIcon/> Statistics </> ),
        navto: '/statistics'
    },
    {
        id: 4,
        link: 'Settings',
        child: ( <> <SettingsApplicationsIcon/> Settings </> ),
        navto: '/profile'
    },
  ];


  return (
    <div className='hidden lg:block fixed z-10'>
      <ul className='flex flex-col justify-start items-left relative top-20 left-0 w-60 h-screen text-gray-800 shadow-md'>
        {links.map(({id, child, navto}) => (
          <li key={id} className='px-2 ml-6 cursor-pointer capitalize text-white-500 duration-200 py-3 text-sm'>
            <Link to={navto}>{child}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NavBar;