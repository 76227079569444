import "./new.scss";
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import {useEffect, useState} from 'react';
import { doc, serverTimestamp, setDoc } from "firebase/firestore"; 
import { auth, db, storage } from "../../firebase";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import AppBar from "../../components/AppBar/AppBar";
import NavBar from "../../components/navbar/Navbar";
import { Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';


const NewCountyAdmin = ({inputs, title}) => {

  const [file, setFile] = useState("");

  const [data, setData] = useState({});

  const [per, setPerc] = useState(null);

  const navigate = useNavigate();


  const [firstName, setFName] = useState("");
  const [lastName, setLName] = useState("");
  const [nationalID, setNID] = useState("");
  const [county, setCounty] = useState("");
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [ferror, setFError] = useState("");
  const [lerror, setLError] = useState("");
  const [cerror, setCError] = useState("");
  const [nerror, setNError] = useState("");

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      // console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          setPerc(progress);
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          }
        }, 
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        }, 
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log('File available at', downloadURL);
            setData((prev)=>({...prev, img:downloadURL}))
          });
        }
      );
      
    };
    file && uploadFile();
  }, [file]);

  const handleAdd = async (e) => {
    e.preventDefault();
    if(firstName === "" || firstName === undefined) return setFError("Invalid first name");
        setFError("");

    if(lastName === "" || lastName === undefined) return setLError("Invalid last name");
        setLError("");

    // if(county === "" || county === undefined) return setCError("County name required");
        // setCError("");

    if(nationalID === "" || nationalID === undefined) return setNError("ID number required");
        setNError("");
    try {
      const res = await createUserWithEmailAndPassword (
        auth,
        email,
        password
      );
      await updateProfile(res.user, {
        displayName: firstName,
        email: email,
      }).then(() => {
        console.log('User profile updated');
      }).catch((error) => {
        console.log("This is an error");
      });

      await setDoc(doc(db, "admins", res.user.uid), {
        firstName: firstName,
        lastName: lastName,
        nationalID: nationalID,
        email: email,
        type: type,
        county: county,
        timeStamp: serverTimestamp()
      });
      // navigate(-1);
    } catch (err) {
      console.log(err);
    }
    // Add a new document in collection "countyadmins"
   

    // console.log(res.id);
  };


  const counties = [
    {
      id:1,
      name: 'Mombasa'
    },
    {
      id:2,
      name: 'Kwale'
    },
    {
      id:3,
      name: 'Kilifi'
    },
    {
      id:4,
      name: 'Tana River'
    },
    {
      id:5,
      name: 'Lamu'
    },
    {
      id:6,
      name: 'Taita Taveta'
    },
    {
      id:7,
      name: 'Garissa'
    },
    {
      id:8,
      name: 'Wajir'
    },
    {
      id:9,
      name: 'Mandera'
    },
    {
      id:10,
      name: 'Marsabit'
    },
    {
      id:11,
      name: 'Isiolo'
    },
    {
      id:12,
      name: 'Meru'
    },
    {
      id:13,
      name: 'Tharka-Nithi'
    },
    {
      id:14,
      name: 'Embu'
    },
    {
      id:15,
      name: 'Kitui'
    },
    {
      id:16,
      name: 'Machakos'
    },
    {
      id:17,
      name: 'Makueni'
    },
    {
      id:18,
      name: 'Nyandarua'
    },
    {
      id:19,
      name: 'Nyeri'
    },
    {
      id:20,
      name: 'Kirinyaga'
    },
    {
      id:21,
      name: 'Murang\'a'
    },
    {
      id:22,
      name: 'Kiambu'
    },
    {
      id:23,
      name: 'Turkana'
    },
    {
      id:24,
      name: 'West Pokot'
    },
    {
      id:25,
      name: 'Samburu'
    },
    {
      id:26,
      name: 'Trans Nzoia'
    },
    {
      id:27,
      name: 'Uasin Gishu'
    },
    {
      id:28,
      name: 'Elgeyo Marakwet'
    },
    {
      id:29,
      name: 'Nandi'
    },
    {
      id:30,
      name: 'Baringo'
    },
    {
      id:31,
      name: 'Laikipia'
    },
    {
      id:32,
      name: 'Nakuru'
    },
    {
      id:33,
      name: 'Narok'
    },
    {
      id:34,
      name: 'Kajiado'
    },
    {
      id:35,
      name: 'Kericho'
    },
    {
      id:36,
      name: 'Bomet'
    },
    {
      id:37,
      name: 'Kakamega'
    },
    {
      id:38,
      name: 'Vihiga'
    },
    {
      id:39,
      name: 'Bungoma'
    },
    {
      id:40,
      name: 'Busia'
    },
    {
      id:41,
      name: 'Siaya'
    },
    {
      id:42,
      name: 'Kisumu'
    },
    {
      id:43,
      name: 'Homabay'
    },
    {
      id:44,
      name: 'Migori'
    },
    {
      id:45,
      name: 'Kisii'
    },
    {
      id:46,
      name: 'Nyamira'
    },
    {
      id:47,
      name: 'Nairobi City'
    },
  ];

  const types = [
    {
      id: 1,
      name: 'admin',
    },
    {
      id: 2,
      name: 'countyadmin',
    },
    {
      id: 3,
      name: 'external',
    },
  ];


  return (
    <div className="flex">
        <AppBar/>
        <NavBar/>
      <div className="flex new mt-12 ml-8">
        <div className="lg:ml-60 mt-10 newContainer">
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          
          <div className="right">
            <form onSubmit={handleAdd}>
              {/* <div className="formInput">
                <label htmlFor="file">Image: <DriveFolderUploadOutlinedIcon className="icon"/></label>
                <input type="file" id="file" onChange={(e) => setFile(e.target.files[0])} style={{display: "none"}}/>
                <p>{per}</p>
              </div> */}
            

              <input type="text" placeholder="First Name" onChange={e=>setFName(e.target.value)} className="shadow focus:outline-none py-2 px-2"/>
              <input type="text" placeholder="Last Name" onChange={e=>setLName(e.target.value)} className="shadow focus:outline-none py-2 px-2"/>
              <input type="text" placeholder="National ID" maxLength={8} onChange={e=>setNID(e.target.value)} className="shadow focus:outline-none py-2 px-2"/>
              <FormControl style={{width: "200px"}}>
                    <InputLabel id="demo-simple-select-label" className='font-bold text-black'>Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        defaultValue="type"
                        name="type"
                        onChange={e=>setType(e.target.value)}
                        // required={true}
                    >
                        {types.map((type) => (
                        <MenuItem
                        key={type.id}
                        value={type.name}
                        >{type.name}</MenuItem>))}
                    </Select>
              </FormControl><br></br>
              <FormControl style={{width: "200px"}}>
                    <InputLabel id="demo-simple-select-label" className='font-bold text-black'>County</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={county}
                        defaultValue="county"
                        name="county"
                        onChange={e=>setCounty(e.target.value)}
                        // required={true}
                    >
                        {counties.map((count) => (
                        <MenuItem
                        key={count.id}
                        value={count.name}
                        >{count.name}</MenuItem>))}
                    </Select>
              </FormControl><br></br>
              <input type="email" placeholder="Email" onChange={e=>setEmail(e.target.value)} className="shadow focus:outline-none py-2 px-2"/>
              <input type="text" placeholder="Password" onChange={e=>setPassword(e.target.value)} className="shadow focus:outline-none py-2 px-2"/>
              <button disabled={per !== null && per < 100} type="submit">Send</button>
            </form>
          </div>
          {/* <div className="left">
            <img src={file ? URL.createObjectURL(file): "https://images.pexels.com/photos/4620866/pexels-photo-4620866.jpeg?cs=srgb&dl=pexels-cottonbro-4620866.jpg&fm=jpg"} alt="" />
          </div> */}
        </div>
      </div>
      </div>
    </div>
  )
}

export default NewCountyAdmin