import "./datatable.scss";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { userColumns } from "../../adminsdatasource";
import {Link} from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc, onSnapshot, snapshotEqual } from "firebase/firestore";
import {db} from '../../firebase';
import { CSVLink, CSVDownload } from "react-csv";

const AdminsDatatable = () => {

    const [data,setData] = useState([]);

    useEffect(()=> {
        // LISTEN (REALTIME)
        const unsub = onSnapshot(collection(db, "admins"), (snapShot) => {
            // console.log("Current data: ", doc.data());
            let list = [];

            snapShot.docs.forEach(doc=>{
                list.push({id:doc.id, ...doc.data()});
            });
            setData(list);
        },
        (error) => {
            console.log(error);
        }
        );
        return () => {unsub();};
    },[]);

    console.log(data);

    const actionColumn = [
        {filed: "action", headerName: "Action", width: 100, renderCell:(params)=>{
            return (
                <div className="cellAction">
                    <Link to={`/countyadmins/${params.row.id}`} style={{textDecoration: "none"}}>
                        <div className="viewButton">View</div>
                    </Link>
                    {/* <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div> */}
                </div>
            )
        }}
    ];

    let headers;

    headers = [
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "National ID", key: "nationalID" },
        { label: "Email", key: "email" },
        { label: "County", key: "county" },
    ];

    const handleRowClick = (e) => {

    }
  return (
    <div className="datatable">
        <div className="datatableTitle">
            <p>County Admins</p>
            <div className="flex justify-between">
                <Link to={`/countyadmins/new`}><button className="link mr-11">ADD NEW</button></Link>
                <CSVLink data={data} filename={"tuktukcountyadmins.csv"} headers={headers} className="link text-black" target="_blank">EXPORT</CSVLink>
            </div>
        </div>
        <DataGrid
        className="datagrid"
            rows={data}
            columns={userColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
            components={{ Toolbar: GridToolbar }}
            onRowClick={handleRowClick}
        />
    </div>
  )
}

export default AdminsDatatable