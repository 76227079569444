import React from 'react'
import AppBar from '../../components/AppBar/AppBar'
import AdminsDatatable from '../../components/datatable/AdminsDatatable'
import NavBar from '../../components/navbar/Navbar'

const Admins = () => {
  return (
    <div className='flex'>
        <AppBar/>
        <NavBar/>
        <div className="w-full list mt-12 ml-2">
        <div className="lg:ml-60 mt-10">
          <AdminsDatatable/>
        </div>
      </div>
    </div>
  )
}

export default Admins