import { DataGrid } from '@mui/x-data-grid';
import { userColumns } from '../tablesource/driversdatablesource';
import './datatable.scss';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { collection, onSnapshot, where, query, getDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../../firebase';
import { AuthContext } from '../../../context/AuthContext';


const DriversDatatable = () => {

    // const [user, setUser] = useState(undefined); // initially, user state is unknown
    // useEffect(() => auth.onAuthStateChanged(setUser), []);

    const [data, setData] = useState([]);

    const {currentUser} = useContext(AuthContext);

    console.log(currentUser);

    const [admin, setAdmin] = useState({});

    const [county, setCounty] = useState("");

    useEffect(()=>{
        const fetchDriver = async () => {
            // try {
                const docRef = doc(db, "admins", currentUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setAdmin(docSnap.data());
                    // console.log(admin);
                } else {
                    console.log("No such document");
                }
                // const unsubs = onSnapshot(doc(db, "countyadmins", currentUser.uid), (doc) => {
                //     // console.log("Current data: ", doc.data());
                //     setAdmin(doc.data());
                //     // setCounty(admin.county);
                //     console.log(admin);
                // },
                // (error) => {
                //     console.log(error);
                // }
                // );
                // return () => {unsubs();};
            // } catch (error) {
                // console.log(error)
            // }
        };
        fetchDriver();
    },[currentUser.uid]);

    useEffect(()=> {
        let county = `${admin.county}`;
        if (county === null) {
            return;
        }
        const q = query(collection(db, "drivers"), where("county", "==", county));

        const unsub = onSnapshot(q, (snapShot) => {
            let list = [];

            snapShot.docs.forEach(doc => {
                list.push({id:doc.id, ...doc.data()});
            });
            setData(list);
        },
        (error) => {
            console.log(error);
        }
        );
        return () => {unsub();};
    },[data]);

    const actionColumn = [
        {field: "action", headerName: "Action", width: 100, renderCell:(params)=> {
            return (
                <div className="cellAction">
                    <Link to={`/drivers/${params.row.id}`} style={{textDecoration: "none"}}>
                        <div className="viewButton">View</div>
                    </Link>
                </div>
            )
        }}
    ];
  return (
    <div className='datatable'>
        <div className="datatableTitle">
            <p>Drivers</p>
        </div>
        <DataGrid className='datagrid'
            rows={data}
            columns={userColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
        />
    </div>
  )
}

export default DriversDatatable