import React from 'react';
import "./sidebar.scss";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import StoreIcon from '@mui/icons-material/Store';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import {Link, useNavigate} from "react-router-dom";
import { useContext, useState } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase.js";
import { AuthContext } from "../../context/AuthContext";

// import {DashboardIcon} from "@mui/icons-material";


const Sidebar = () => {

    const navigate = useNavigate();

    const [error, setError] = useState(false);

    // const {state} = useContext(AuthReducer);
    const {currentUser} = useContext(AuthContext);

    const handleLogout = (e) => {
        e.preventDefault();
    
        signOut(auth).then(() => {
        // Sign-out successful.
            // localStorage.removeItem("user"); 
            navigate("/login")
        }).catch((error) => {
        // An error happened.
        setError(true)
        console.log(error);
        });
    
      }

  return (
    <div className='sidebar'>
        <div className='top'>
            <Link to="/" style={{textDecoration: "none"}}>
                <span className='logo'>TukON Admin</span>
            </Link>
        </div>
        <hr/>
        <div className='center'>
            <ul>
                <p className="title">MAIN</p>
                <Link to="/" style={{textDecoration: "none"}}>
                    <li>
                        <DashboardIcon className='icon'/>
                        <span>Dashboard</span>
                    </li>
                </Link>
                <p className="title">LISTS</p>
                <Link to="/users" style={{textDecoration: "none"}}>
                    <li>
                        <PersonOutlineOutlinedIcon className='icon'/>
                        <span>Users</span>
                    </li>
                </Link>
                <Link to="/drivers" style={{textDecoration: "none"}}>
                    <li>
                        <ElectricRickshawIcon className='icon'/>
                        <span>Drivers</span>
                    </li>
                </Link>
                <Link to="/products" style={{textDecoration: "none"}}>
                    <li>
                        <StoreIcon className='icon'/>
                        <span>Products</span>
                    </li>
                </Link>
                
                <li>
                    <CreditCardIcon className='icon'/>
                    <span>Orders</span>
                </li>
                <li>
                    <LocalShippingIcon className='icon'/>
                    <span>Delivery</span>
                </li>
                <p className="title">USEFUL</p>
                <li>
                    <InsertChartIcon className='icon'/>
                    <span>Stats</span>
                </li>
                <li>
                    <NotificationsNoneIcon className='icon'/>
                    <span>Notifications</span>
                </li>
                <p className="title">SERVICE</p>
                <li>
                    <SettingsSystemDaydreamIcon className='icon'/>
                    <span>System Health</span>
                </li>
                <li>
                    <PsychologyOutlinedIcon className='icon'/>
                    <span>Logs</span>
                </li>
                <li>
                    <SettingsApplicationsIcon className='icon'/>
                    <span>Settings</span>
                </li>
                <p className="title">USER</p>
                <li>
                    <AccountCircleOutlinedIcon className='icon'/>
                    <span>Profile</span>
                </li>
                <li onClick={ handleLogout }>
                    <ExitToAppOutlinedIcon className='icon'/>
                    <span>Logout</span>
                </li>
            </ul>
        </div>
        {/* <div className='bottom'>
            <div className="colorOption" onClick={()=> dispatch({type:"LIGHT"})}></div>
            <div className="colorOption" onClick={()=> dispatch({type:"DARK"})}></div>
        </div> */}
    </div>
  );

}

export default Sidebar