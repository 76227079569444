import React from 'react'
import Chart from '../../components/chart/Chart'
import Featured from '../../components/featured/Featured'
import NavBar from '../components/navbar/Navbar'
import Widget from '../../components/widget/Widget'
import AppBar from '../components/AppBar/AppBar'

const CountyAdminHome = () => {
  return (
    <div className='flex'>
      <AppBar/>
      <NavBar/>
      <div className='mt-12 justify-center ml-8'>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 widgets lg:ml-60 mt-10">
            <Widget type="driver"/>
            <Widget type="male"/>
            <Widget type="female"/>
            <Widget type="licence"/>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 charts lg:ml-60 ml-0">
            <Featured/>
            <Chart title="Last 6 Months (Registered drivers)" aspect={2 / 1}/>
          </div>
        </div>
    </div>
  )
}

export default CountyAdminHome