import "./single.scss";
import NavBar from '../../components/navbar/Navbar';
import AppBar from '../../components/AppBar/AppBar';
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { Link, useLocation } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { collection, doc, onSnapshot, getDoc } from "firebase/firestore";
import {db} from '../../firebase';
import {ReactPDF, Image, Document, Page, Text, View, StyleSheet, PDFDownloadLink, pdf, PDFRenderer, PDFViewer } from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import tukonlogo from "../../assets/tukonlogo2.jpeg"

const SingleCountyAdmin = () => {
  const path = window.location.pathname.split("/")[2];
  // console.log(path);

  const [data,setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      // let list = [];
      try {
        const docRef = doc(db, "admins", path);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data());
          setData(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        // console.log(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  },[path]);


  // pdf stylesheet
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    image: {
      width: '50%',
      padding: 10,
    },
  });

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
          <Image style={styles.image} src={data.image} />
        </View>
        <View style={styles.section}>
          <Text>{data.driverfirstName}</Text>
          <Text>{data.driverlastName}</Text>
        </View>
      </Page>
    </Document>
  );

  // const createpdf = () => {
  //   PDFViewer.render(<MyDocument />, 'example.pdf');
  // }

  const Doc = () => (
    <PDFViewer>
      <MyDocument />
    </PDFViewer>
  );
  
  const createpdf = async () => {
    // PDFViewer.render(<MyDocument />, 'example.pdf');
    // ReactDOM.render(<Doc />, document.getElementById('root'));
    window.open(<Doc/>, '_blank');
  }
  // ReactDOM.render(<Doc />, document.getElementById('root'));
  return (
    <div className="flex">
      <AppBar/>
      <NavBar/>
      <div className="singleContainer w-full list mt-12 ml-8">
        <div className="lg:ml-60 mt-10">
        <div className="top">
          <div className="left">
            {/* <div className="editButton">Edit</div> */}
            <h1 className="itemTitle title">Admin's Details</h1>
            <div className="item">
              <img src={data.image} alt="" className="itemImg" />
              <div className="details">
                {/* <h1 className="itemTitle">Driver's Details</h1> */}
                <div className="detailItem">
                  <span className="itemKey">First Name:</span>
                  <span className="itemValue">{data.firstName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Last Name:</span>
                  <span className="itemValue">{data.lastName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">National ID:</span>
                  <span className="itemValue">{data.nationalID}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{data.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">County:</span>
                  <span className="itemValue">{data.county}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
          <div className="item">
              <div className="details">
                <div className="mt-5">
                    <button className="bg-black text-white py-1 px-2" onClick={createpdf}>Export</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default SingleCountyAdmin