export const userColumns =[
    { field: 'id', headerName: 'UID', width: 70 },{
    field:"user", headerName:"Photo", width: 80, renderCell: (params) => {
        return (
            <div className="cellWithImg">
                <img className="cellImg" src={params.row.image} alt="avatar"/>
                {params.row.driversname}
            </div>
        );
    },
},
{
    field: "firstName", headerName:"First Name", width: 100,
},
{
    field: "lastName", headerName:"Last Name", width: 100,
},
{
    field: "nationalID",
    headerName: "National ID",
    width: 100,
},
{
    field: "email",
    headerName: "Email",
    width: 220,
},
{
    field: "type",
    headerName: "Type",
    width: 120,
},
{
    field: "county",
    headerName: "County",
    width: 100,
}

];