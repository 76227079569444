import "./list.scss";
import UsersDatatable from "../../components/datatable/UsersDatatable";
import NavBar from '../../components/navbar/Navbar';
import AppBar from '../../components/AppBar/AppBar';

const UsersList = () => {
  return (
    <div className="flex">
      <AppBar/>
      <NavBar/>
      <div className="w-full list mt-12 ml-2">
        <div className="lg:ml-60 mt-10">
          <UsersDatatable/>
        </div>
      </div>
    </div>
  )
}

export default UsersList