import React, { useContext, useState, useEffect } from 'react';
import tukonlogo from '../../assets/tukonlogo2.jpeg';
import {FaBars, FaTimes} from 'react-icons/fa';
import {Link, useNavigate} from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import StoreIcon from '@mui/icons-material/Store';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { AuthContext } from '../../context/AuthContext';
import { collection, doc, onSnapshot, getDoc } from "firebase/firestore";
import {db} from '../../firebase';
import { signOut } from "firebase/auth";
import { auth } from "../../firebase.js";

const AppBar = () => {

  const {currentUser } = useContext(AuthContext);

  // console.log(currentUser);

  const [nav, setNav] = useState(false);

  const [data,setData] = useState({});

  const {dispatch} = useContext(AuthContext);

  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const handleLogout = (e) => {
    e.preventDefault();

    signOut(auth).then(() => {
    // Sign-out successful.
        navigate("/")
    }).catch((error) => {
    // An error happened.
    setError(true)
    console.log(error);
    });

  }


  useEffect(() => {
    const fetchData = async () => {
      // let list = [];
      try {
        const docRef = doc(db, "admins", currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data());
          setData(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        // console.log(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  });

  const links = [
    {
      id: 1,
      child: ( <> <DashboardIcon/> Dashboard </> ),
      navto: '/home'
    },
    {
      id: 2,
      link: 'Users',
      child: ( <> <PersonOutlineOutlinedIcon/> Users </> ),
      navto: '/users'
    },
    {
      id: 3,
      link: 'Drivers',
      child: ( <> <ElectricRickshawIcon/> Drivers </> ),
      navto: '/drivers'
    },
    {
      id: 4,
      link: 'County Admins',
      child: ( <> <AdminPanelSettingsIcon/> Admins </> ),
      navto: '/countyadmins'
    },
    {
        id: 6,
        link: 'Statistics',
        child: ( <> <InsertChartIcon/> Statistics </> ),
        navto: '/statistics'
    },
    {
        id: 8,
        link: 'Settings',
        child: ( <> <SettingsApplicationsIcon/> Settings </> ),
        navto: '/profile'
    }
  ];

  return (
    <div className='flex justify-between items-center w-full h-20 px-4 text-gray-800 bg-white shadow-sm fixed z-50'>
        <div onClick={()=> setNav(!nav)} className='cursor-pointer pr-4 z-10 text-gray-800 lg:hidden'>
            {nav ? <FaBars size={30} /> : <FaBars size={30} />}
        </div>
        <div>
            <img src={tukonlogo} alt="TukON" width={120} height={70}/>
        </div>
        <div className='flex justify-between items-center'>
          <Link to="profile">
            <img src={data.image} alt="" className='w-20 h-20 object-cover rounded-full cursor-pointer p-2 hidden md:block'/>
          </Link>
          <li onClick={handleLogout} className='cursor-pointer'>
            <ExitToAppOutlinedIcon style={{color: "black"}} />
          </li>
        </div>

        {nav && (
            <>
                <ul className='flex flex-col justify-start items-left absolute top-20 left-0 w-1/4 h-screen bg-white text-gray-800 transition ease-out duration-500 shadow-md'>
                {links.map(({id, child, navto}) => (
                    <li key={id} className='px-4 cursor-pointer capitalize py-3 text-sm'>
                        <Link onClick={() => setNav(!nav)} to={navto} >{child}</Link>
                    </li>
                ))}
                </ul>
            </> 
        )}
    </div>
  )
}

export default AppBar