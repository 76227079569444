import "./datatable.scss";
import { DataGrid } from '@mui/x-data-grid';
import { userColumns, userRows } from "../../userdatatablesource";
import {Link} from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc, onSnapshot, snapshotEqual } from "firebase/firestore";
import {db} from '../../firebase';
import { CSVLink, CSVDownload } from "react-csv";

const UsersDatatable = () => {

    const [data,setData] = useState([]);

    useEffect(()=> {

        // LISTEN (REALTIME)
        const unsub = onSnapshot(collection(db, "users"), (snapShot) => {
            // console.log("Current data: ", doc.data());
            let list = [];

            snapShot.docs.forEach(doc=>{
                list.push({id:doc.id, ...doc.data()});
            });
            setData(list);
        },
        (error) => {
            console.log(error);
        }
        );
        return () => {unsub();};
    },[]);

    // console.log(data);

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, "drivers", id));
        } catch (err) {
            console.log(err);
        }
        setData(data.filter((item) => item.id !== id));
    };

    const actionColumn = [
        {filed: "action", headerName: "Action", width: 200, renderCell:(params)=>{
            return (
                <div className="cellAction">
                    <Link to="/drivers/test" style={{textDecoration: "none"}}>
                        <div className="viewButton">View</div>
                    </Link>
                    {/* <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div> */}
                </div>
            )
        }}
    ];

    let headers;

    headers = [
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Email", key: "email" },
        { label: "Phone Number", key: "phoneNumber" },
    ];
  return (
    <div className="datatable">
        <div className="datatableTitle">
            {/* Add New User
            <Link to="/drivers/new" style={{textDecoration: "none"}} className="link">
                Add New
            </Link> */}
            {/* <Link to="/drivers/new" style={{textDecoration: "none"}} className="link">
                Export
            </Link> */}
            <p>Users</p>
            <CSVLink data={data} filename={"tuktukcustomers.csv"} headers={headers} className="link text-black" target="_blank">EXPORT</CSVLink>
        </div>
        <DataGrid
        className="datagrid"
            rows={data}
            columns={userColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
        />
    </div>
  )
}

export default UsersDatatable