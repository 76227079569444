import "./featured.scss"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useState } from "react";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";

const Featured = () => {

    const today = new Date();
    today.setHours(0,0,0,0);

    // const previous = today.setDate(today.getDate() - 1);

    // console.log(today);

    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [diff, setDiff] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          // let list = [];
          try {
            // const docRef = doc(db, "drivers", 'IYLJfzdvVjVArb7UlHthZgBwP132');
            // const docDat = await getDoc(docRef);
            // console.log(docDat.data().timeStamp);


            const totaltoday = query(collection(db, "drivers"), where("timeStamp", ">", today));
            const docSnap = await getDocs(totaltoday);
            // console.log(docSnap)
            setData(docSnap.docs.length);

            // diff between today & overall
            const totaldr = query(collection(db, "drivers"));
            const totals = await getDocs(totaldr);
            setTotal(totals.docs.length);
            // console.log('Total '+ total);
            // difference
            setDiff((data / total) * 100);
            // console.log("diff " + diff);

          } catch (err) {
            // console.log(err);
          }
        };
        fetchData();
    },[today]);

  return (
    <div className="featured">
        <div className="top">
            <h1 className="title">Total Registrations Today</h1>
            <MoreVertIcon fontSize="small"/>
        </div>
        <div className="bottom">
            <div className="featuredChart">
                <CircularProgressbar value={diff} text={`${Number(diff).toFixed(2)}%`} strokeWidth={5}/>
            </div>
            <p className="title">Total drivers registered today</p>
            <p className="amount">{data}</p>
            <p className="desc">Previous data processing. Last figures may not be included.</p>
            <div className="summary">
                <div className="item">
                    <div className="itemTitle">Target</div>
                    <div className="itemResult positive">
                        <KeyboardArrowUpOutlinedIcon fontSize="small"/>
                        {/* <div className="resultAmount">12.4k</div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="itemTitle">Last Week</div>
                    <div className="itemResult negative">
                        <KeyboardArrowDownIcon fontSize="small"/>
                        {/* <div className="resultAmount">12.4k</div> */}
                    </div>
                    
                </div>
                <div className="item">
                    <div className="itemTitle">Last Month</div>
                    <div className="itemResult positive">
                        <KeyboardArrowUpOutlinedIcon fontSize="small"/>
                        {/* <div className="resultAmount">12.4k</div> */}
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Featured;