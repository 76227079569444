import "./list.scss"
import DriversDatatable from "../../components/datatable/Driversdatatable";
import NavBar from '../../components/navbar/Navbar'
import AppBar from '../../components/AppBar/AppBar'

const CountyDriversList = () => {
  return (
    <div className="flex">
      <AppBar/>
      <NavBar/>
      <div className="w-full list mt-12 ml-2">
        <div className="lg:ml-60 mt-10">
          <DriversDatatable/>
        </div>
      </div>
    </div>
  )
}

export default CountyDriversList